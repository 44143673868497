<template>
  <div
    class="expression-editor"
    :class="{ 'border-l': depth > 0 }"
    :style="{ borderColor: colorByPrevDepth }">
    <span class="label"> type </span>
    <div class="flex">
      <label v-if="!showJson">
        <singleselect
          v-model="expressionType"
          class="select-input"
          :has-option-groups="true"
          :return-as-object="false"
          :options="typeOptions"
          label="label"
          track-by="key" />
      </label>
      <label v-if="showJson">
        <b-input
          v-model="expressionJSON"
          class="input-item text-input"
          type="textarea" />
      </label>
      <button
        class="button is-light ml-2"
        @click="clearSelf">
        Clear
      </button>
      <button
        class="button is-info is-light ml-2"
        @click="showJson = !showJson">
        {{ showJson ? "GUI" : "JSON" }}
      </button>
    </div>
    <template v-if="!showJson">
      <div
        v-for="arg in args"
        :key="arg">
        <template v-if="argsMap[arg] === 'company_expression'">
          <div>
            <span class="label">{{ arg }} </span>

            <singleselect
              class="select-input"
              :value="getArgValue(arg)"
              :return-as-object="false"
              label="name"
              track-by="id"
              :options="Object.values(companyExpressions)"
              @input="setArgValue(arg, $event)" />
          </div>
        </template>
        <template v-if="argsMap[arg] === 'entity_attribute'">
          <label>
            <span class="label">{{ arg }} </span>
            <singleselect
              class="select-input"
              :return-as-object="false"
              label="name"
              :options="entityAttributes"
              track-by="key"
              :value="getArgValue(arg)?.key"
              @input="
                setArgValue(
                  arg,
                  entityAttributes.filter((a) => a?.key == $event).shift()
                )
              " />
          </label>
        </template>
        <template v-if="argsMap[arg] === 'string'">
          <span class="label">{{ arg }} </span>
          <b-input
            class="input-item text-input"
            :value="getArgValue(arg)"
            @input="setArgValue(arg, $event)" />
        </template>
        <template v-if="argsMap[arg] === 'expression'">
          <div class="group">
            <b-collapse animation="slide">
              <template #trigger="props">
                <span
                  :aria-expanded="props.open"
                  class="label group-label"
                  :style="{ color: colorByDepth }">
                  {{ arg }}
                  <b-icon
                    class="icon"
                    :icon="props.open ? 'menu-up' : 'menu-down'" />
                </span>
              </template>
              <div
                class="start"
                :style="{ borderColor: colorByDepth }" />
              <expression-editor
                :depth="depth + 1"
                :expression="getArgValue(arg) || {}"
                class="sub-expression"
                @update:expression="setArgValue(arg, $event)" />
              <div
                class="end"
                :style="{ borderColor: colorByDepth }" />
            </b-collapse>
          </div>
        </template>
        <template v-if="argsMap[arg] === 'expression_list'">
          <div class="group">
            <b-collapse animation="slide">
              <template #trigger="props">
                <span
                  :aria-expanded="props.open"
                  class="label group-label"
                  :style="{ color: colorByDepth }">
                  {{ arg }}
                  <b-icon
                    class="icon"
                    :icon="props.open ? 'menu-up' : 'menu-down'" />
                </span>
              </template>
              <div
                class="start"
                :style="{ borderColor: colorByDepth }" />
              <div
                v-for="(subExpression, idx) in getArgValue(arg)"
                :key="`${arg}-${idx}`"
                class="expression-list-item">
                <expression-editor
                  :depth="depth + 1"
                  :expression="subExpression"
                  class="sub-expression"
                  @update:expression="setListArgValue(arg, idx, $event)" />
                <button
                  class="button is-danger is-light is-small remove-btn"
                  @click="removeListArgValue(arg, subExpression)">
                  <b-icon
                    icon="delete"
                    style="font-size: 15px"
                    size="is-small" />
                </button>
              </div>
              <div
                class="end"
                :style="{ borderColor: colorByDepth }" />

              <button
                class="button is-small is-primary mt-5 ml-2"
                @click="addListArgValue(arg)">
                Add expression
              </button>
            </b-collapse>
          </div>
        </template>
        <template v-if="argsMap[arg] === 'type'">
          <form>
            <span class="label"> {{ arg }} </span>
            <b-select
              class="input-item"
              :value="(getArgValue(arg) || {})?.key"
              @input="setArgValue(arg, { key: $event })">
              <option
                disabled
                value="">
                Select a type
              </option>
              <template v-for="key in Object.keys(dataTypes)">
                <option
                  :key="key"
                  :value="key">
                  {{ dataTypes[key].title }}
                </option>
              </template>
            </b-select>
          </form>
        </template>
        <template v-if="argsMap[arg] === 'field'">
          <label>
            <span class="label"> {{ arg }} </span>
            <singleselect
              :value="getArgValue(arg)?.key"
              class="select-input"
              :has-option-groups="true"
              :return-as-object="false"
              :options="fieldOptionsWithGroups"
              label="label"
              track-by="key"
              @input="setArgValue(arg, { key: $event })" />
          </label>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import { expressionProcessorMixin } from '@/components/reporting/expressionProcessorMixin'
  import { mapGetters } from 'vuex'
  import singleselect from '@/components/ui/singleselect.vue'

  export default {
    name: 'ExpressionEditor',
    components: {
      singleselect,
    },
    mixins: [expressionProcessorMixin,],
    props: {
      expression: { type: Object, required: true, },
      depth: {
        type: Number,
        default: 0,
      },
    },
    data () {
      return {
        showJson: false,
        drilldownExpressionTypes: {
          drilldown_filter: {
            args: { expression: 'expression', },
            type: 'drilldown_filter',
          },
          drilldown_label_expression: {
            args: {},
            type: 'drilldown_label',
          },
          drilldown_key_expression: {
            args: {},
            type: 'drilldown_key',
          },
          drilldown_label_field: {
            args: {},
            type: 'drilldown_label_field',
          },
          drilldown_key_field: {
            args: {},
            type: 'drilldown_key_field',
          },
        },
        value: null,

        depthColors: [
          '#1E90FF',
          'green',
          'purple',
          'orange',
          'tomato',
          '#BA55D3',
        ],
      }
    },
    computed: {
      ...mapGetters({
        dataTypes: 'expressions/dataTypes',
        fieldOptions: 'expressions/fieldOptions',
        views: 'expressions/views',
        expressionOptions: 'expressions/expressionTypes',
        entityAttributes: 'expressions/entityAttributes',
      }),

      fieldOptionsWithGroups () {
        return this.fieldOptions.map((field) => {
          return {
            ...field,
            group: this.views.find((view) => view.key === field.view).label,
          }
        })
      },

      drilldownExpressionTypeKeys () {
        return Object.keys(this.drilldownExpressionTypes)
      },

      colorByDepth () {
        return this.depthColors[this.depth % this.depthColors.length]
      },
      colorByPrevDepth () {
        return this.depthColors[(this.depth - 1) % this.depthColors.length]
      },

      expressionJSON: {
        get () {
          return JSON.stringify(this.expression)
        },
        set (value) {
          this.$emit('update:expression', JSON.parse(value))
        },
      },
      expressionTypeOptions () {
        return Object.keys(this.expressionOptions)
      },
      typeOptions () {
        return [
          ...this.dateFilterOptionKeys.map((key) => ({
            key,
            label: key,
            group: 'Date Picker Filters',
          })),

          ...this.controlFilterOptionKeys.map((key) => ({
            key,
            label: key,
            group: 'Dimension Select Filters',
          })),

          { key: 'company_expression', label: 'company_expression', group: 'Company Expression', },

          ...this.drilldownExpressionTypeKeys.map((key) => ({
            key,
            label: key,
            group: 'Drilldown Expressions',
          })),

          ...this.expressionTypeOptions.reduce((acc, key) => {
            console.log(this.expressionOptions[key])
            let group

            if (this.expressionOptions[key]?.type_category === 'non_primitive') {
              group = 'Non-Primitive Backend Expressions'
            } else if (this.expressionOptions[key]?.type_category === 'primitive') {
              group = 'Primitive Backend Expressions'
            } else if (this.expressionOptions[key]?.type_category === 'hybrid') {
              group = 'Hybrid Backend Expressions'
            }

            if (group) {
              acc.push({
                key,
                label: key,
                group,
              })
            }

            return acc
          }, []),

        ]
      },
      argsMap () {
        if (
          !this.expressionTypeOptions.includes(this.expressionType) &&
          !this.dateFilterOptionKeys.includes(this.expressionType) &&
          !this.controlFilterOptionKeys.includes(this.expressionType) &&
          this.expressionType != 'company_expression' &&
          !Object.keys(this.drilldownExpressionTypes).includes(
            this.expressionType
          )
        ) {
          return {}
        }
        return {
          ...this.expressionOptions,
          ...this.dateFilterExpressionOptions,
          ...this.controlFilterExpressionOptions,
          ...this.drilldownExpressionTypes,
          company_expression: { args: { expression_id: 'company_expression', }, },
        }[this.expressionType].args
      },
      args () {
        return Object.keys(this.argsMap)
      },
      expressionType: {
        get () {
          return (this.expression || {}).type
        },
        set (type) {
          let newExp = { type, }
          let typeArgs = {
            ...this.expressionOptions,
            ...this.dateFilterExpressionOptions,
            ...this.controlFilterExpressionOptions,
            ...this.drilldownExpressionTypes,
            company_expression: { args: { expression_id: 'company_expression', }, },
          }[type].args
          for (let arg of Object.keys(typeArgs)) {
            if (typeArgs[arg] === 'expression') {
              newExp[arg] = {}
            } else if (typeArgs[arg] === 'expression_list') {
              newExp[arg] = []
            } else if (typeArgs[arg] === 'entity_attribute') {
              newExp[arg] = {}
            } else if (typeArgs[arg] === 'field') {
              newExp[arg] = {}
            } else if (typeArgs[arg] === 'string') {
              newExp[arg] = ''
            }
          }
          this.$emit('update:expression', newExp)
        },
      },
    },

    methods: {
      clearSelf () {
        this.$emit('update:expression', {})
      },
      getArgValue (arg) {
        return this.expression[arg]
      },
      setArgValue (arg, value) {
        let newExp = { ...this.expression, }
        newExp[arg] = value
        this.$emit('update:expression', newExp)
      },
      setListArgValue (arg, idx, value) {
        let newExp = { ...this.expression, }
        newExp[arg].splice(idx, 1, value)
        this.$emit('update:expression', newExp)
      },
      removeListArgValue (arg, value) {
        let newExp = { ...this.expression, }
        newExp[arg] = [...newExp[arg].filter((o) => o != value),]
        this.$emit('update:expression', newExp)
      },
      addListArgValue (arg) {
        let newExp = { ...this.expression, }
        newExp[arg].push({})
        this.$emit('update:expression', newExp)
      },
    },
  }
</script>

<style lang="scss" scoped>
.sub-expression {
  // padding-left: 4 * $gridBase;
}

.expression-editor {
  // display: flex;
}

.flex {
  display: flex;
}

// .group > .label {
//   font-size: 15px;
//   font-weight: 500;
// }

.group-label {
  position: relative;
  top: 13px;
  left: 30px;
  font-size: 16px !important;
  font-weight: 500;
  .icon {
    margin-left: 5px;
    position: relative;
    top: 3px;
  }
}

form {
  margin-top: 10px;
}

.start,
.end {
  width: 20px;
  border-bottom: $border-1;
  position: relative;
}

.start {
  position: relative;
  top: 0px;
  left: 5px;
}

.end {
  position: relative;
  top: 0px;
  left: 5px;
}

.border-l {
  border-left: $border-1;
  padding: 20px;
  margin-left: 5px;
  // margin-top: 10px;
  // margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.02);
  &:hover {
    background-color: rgba(0, 0, 0, 0.04)
  }
}

.group {
  // background-color: rgba(0, 140, 132, 0.03);
  margin-bottom: 10px;
}

.remove-btn {
  position: absolute !important;
  top: 10px;
  right: 10px;
}
span.label {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 300;
  // margin-top: 10px;
}

.expression-list-item {
  position: relative !important;
}

.select-input {
  min-width: 300px;
  max-width: 350px;

}
</style>

<style lang="scss">
.expression-editor {
  .input-item {
    min-width: 300px;
    margin-bottom: 10px;

    select {
      min-width: 300px;
      width: auto;
    }

    &.text-input {
      max-width: 300px !important;
    }
  }
}
</style>
