<template>
  <div class="layout">
    <vuedraggable
      group="measureGroups"
      ghost-class="ghost"
      class="dropzone"
      :list="data">
      <div
        v-for="(measureGroup, i) in data"
        :key="measureGroup.key">
        <LayoutGroupItem
          :value="measureGroup"
          :current-selected-key="currentSelectedKey"
          :context="context"
          @delete="deleteItem(measureGroup)"
          @paste="pasteCode(i)" />
      </div>
    </vuedraggable>
    <button
      class="button add-row-button"
      @click="addNewItem">
      <img
        src="@/assets/icons/add-row.svg"
        alt="" />

      <div>Add item</div>
    </button>

    <b-modal
      v-model="modulePickerModal"
      :can-cancel="['x', 'escape']"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      close-button-aria-label="Close"
      aria-modal>
      <form action="">
        <div class="modal-card select-module-modal">
          <header class="modal-header">
            <h2>Add Object</h2>
            <button
              type="button"
              class="delete"
              @click="modulePickerModal = false" />
          </header>
          <div class="modal-body">
            <div
              v-for="item in moduleOptions"
              :key="item.type">
              <div
                class="miniview-item"
                @click="addModuleBelow(item)">
                <i class="material-icons mr-3"> {{ item.icon }} </i>

                <div>{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
  import uniqid from 'uniqid'
  import vuedraggable from 'vuedraggable'
  import {
    measureGroupObject,
    pivotGroupObject
  } from '@/components/pdf_report/table_module/modules.js'
  import LayoutGroupItem from '@/components/pdf_report/table_module/table_cols/LayoutGroupItem'

  export default {
    name: 'ColumnLayout',
    components: {
      vuedraggable,
      LayoutGroupItem,
    },
    provide () {
      return {
        openModule: this.openModule,
      }
    },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      context: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        currentSelectedKey: null,
        modulePickerModal: false,

        moduleOptions: [
          {
            name: 'Measure Group',
            type: 'MeasureGroup',
            icon: 'folder',
          },

          {
            name: 'Pivot Group',
            type: 'PivotGroup',
            icon: 'alt_route',
          },

        // {
        //   name: 'Measure',
        //   type: 'Measure',
        //   icon: 'functions',
        // },
        ],
      }
    },

    computed: {
      isPivotGroup () {
        return this.data.table_pivot !== null
      },

      data: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    beforeCreate: function () {
      this.$options.components.LayoutGroupItem =
        require('@/components/pdf_report/table_module/table_cols/LayoutGroupItem.vue').default
    },

    mounted () {
      if (!this.data.key) {
        this.data.key = uniqid()
      }
    },

    beforeDestroy () {},

    methods: {
      addNewItem () {
        this.modulePickerModal = true
      },

      async pasteCode (index) {
        const clipboard = await navigator.clipboard.readText()
        const replacedClipboard = clipboard.replace(
          /"id":"([^"]*)"/g,
          (match, id) => `"id":"${id}_1"`
        )
        try {
          const data = JSON.parse(replacedClipboard)
          if (data.type === 'group') {
            this.data.splice(index + 1, 0, {
              ...data.code,
              key: uniqid(),
            })
          } else {
            this.$buefy.toast.open({
              message: 'This module cannot be pasted here.',
              position: 'is-bottom',
              type: 'is-warning',
            })
          }
        } catch (e) {
          this.$buefy.toast.open({
            message: 'Error',
            position: 'is-bottom',
            type: 'is-warning',
          })
        }
      },
      deleteItem (module) {
        this.data = this.data.filter((item) => module.key !== item.key)
      },

      addModuleBelow (item) {
        if (item.type === 'MeasureGroup') {
          item = {
            id: uniqid(),
            key: uniqid(),
            ...measureGroupObject(),
          }
        } else if (item.type === 'PivotGroup') {
          item = {
            id: uniqid(),
            key: uniqid(),
            ...pivotGroupObject(),
          }
        }
        this.data.push({ ...item, key: uniqid(), })
        this.modulePickerModal = false
      },

      openModule (elementObject, type, context) {
        this.currentSelectedKey = elementObject.id
        return this.$emit('open', elementObject, type, context)
      },
    },
  }
</script>

<style lang="scss" scoped>
// .rows {

//   button {
//     display: flex;
//     align-items: flex-start;
//     color: $primary;
//     border: 1px solid rgba(0,0,0,0.2)
//     background-color: rgba(0, 0, 0, 0.04);
//     justify-content: flex-start;

//     &:hover {
//       color: $primary;
//       border: 1px solid rgba(0,0,0,0.2)
//     }
//   }

//   .add-module-below-section {
//     height: 8px;
//     cursor: copy;

//     &:hover {
//       background-color: #f2f9f9;
//     }
//   }
// }

.layout {
  overflow: auto;
  height: calc(100%);
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.mini-view-item {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s;
  width: 100%;
  padding: 10px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  font-size: 15px;
  background-color: white;
  box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
    0px 1px 2px 0px rgba(40, 39, 43, 0.06);

  img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    transition: background-color 0.1s;
    border: $border-1;
  }
  &.selected {
    background-color: $primary;
    color: white;
    img {
      filter: brightness(0) invert(1);
    }
  }
}
.add-row-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  justify-content: start !important;
  color: $grayscale-2 !important;
  background-color: #fbfbfb;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.02) !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
}
.select-module-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;

  .modal-header {
    display: flex;
    h2 {
      font-size: 18px;
      width: 100%;
    }
  }

  .modal-body {
    margin-top: 30px;

    .miniview-item {
      cursor: pointer;
      padding: 10px;
      margin-bottom: 10px;
      min-width: 160px;
      display: flex;
      border-radius: 4px;
      align-items: center;
      font-size: 15px;
      background-color: white;
      border: 1px solid #f5f5f5;
      box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
        0px 1px 2px 0px rgba(40, 39, 43, 0.06);
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      margin-right: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}
</style>
