<template>
  <b-button
    :type="type"
    :icon="icon"
    :class="[size]"
    :size="size"
    :disabled="disabled"
    :loading="loading"
    @click="$emit('click')">
    <slot />

    <span
      v-if="hotkeys.length"
      class="hotkeys">
      {{ formattedHotkeys }}
    </span>
  </b-button>
</template>

<script>
  export default {
    name: 'AButton',
    props: {
      loading: { type: Boolean, default: false, },
      disabled: { type: Boolean, default: false, },
      icon: { type: String, default: '', },
      label: { type: String, default: '', },
      type: { type: String, default: '', },
      size: { type: String, default: '', },
      hotkeys: { type: String, default: '', },
    },
    data () {
      return {}
    },
    computed: {
      formattedHotkeys () {
        return this.hotkeys.split('+').map(key => {
          return key
        }).join(' + ')
      },
    },
    mounted () {
      window.addEventListener('keydown', this.shortcutHandler)
    },
    beforeDestroy () {
      window.removeEventListener('keydown', this.shortcutHandler)
    },
    methods: {
      shortcutHandler (e) {
        const keys = this.hotkeys.split('+')
        const matchesAll = keys.every(key => {
          if (key.toLowerCase() === 'shift') {
            return e.shiftKey
          }
          return e[key.toLowerCase() + 'Key'] || e.key.toLowerCase() === key.toLowerCase()
        })

        if (matchesAll) {
          this.$emit('click')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.hotkeys {
  background-color: rgba(205, 205, 205, 0.2);
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 6px;
  margin-left: 8px;
}

.button {
  display: flex;
  padding: 0 6px 0 10px !important;

  &.is-small {
    .hotkeys {
      font-size: 10px;
      border-radius: 4px;
      padding: 4px 6px;
      margin-left: 8px;
    }
  }
}
</style>
