<template>
  <div class="sub-section">
    <b-field
      class="input-field"
      label="">
      <FilterConfigListEditor v-model="inputs" />
    </b-field>
  </div>
</template>

<script>

  import FilterConfigListEditor from '@/components/reports/config/modules/common/FilterConfigListEditor'

  export default {
    name: 'FiltersTab',
    components: {
      FilterConfigListEditor,
    },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      inputs: {
        get () {
          return this.value || []
        },
        set (value) {
          this.$emit('input', value ? value : [])
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
.sub-section {
  padding: 16px;
  overflow: auto;
}
</style>
