<template>
  <div class="mini-view-item-container mb-4">
    <div class="mini-view-item">
      <img
        :src="types[data.type].icon"
        alt="" />
      <div>{{ data.title || types[data.type].name }}</div>
      <div
        v-if="data.id"
        class="right">
        <b-button
          class="action-btn"
          size="is-small"
          icon-right="content-copy"
          @click="$emit('duplicate')" />
        <b-button
          type="is-danger is-light"
          class="action-btn ml-2"
          size="is-small"
          icon-right="delete"
          @click="$emit('delete')" />

        <router-link
          v-if="types[data.type].route"
          class="ml-2"
          :to="{
            name: types[data.type].route,
            params: { module_id: data.id },
          }">
          <b-button
            type="is-primary"
            class="action-btn"
            size="is-small"
            icon-right="lead-pencil" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import uniqid from 'uniqid'
  export default {
    name: 'MiniViewModule',
    components: {},
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        toggleTrackerDropdown: false,
        module: {},
        types: {
          TitleModule: {
            name: 'Title',
            route: 'pdf_report_title_module_editor',
            icon: require('@/assets/icons/page-header.svg'),
          },

          SectionDividerModule: {
            name: 'Section Divider',
            route: 'pdf_report_section_divider_editor',
            icon: require('@/assets/icons/section-divider.svg'),
          },

          PageBreakModule: {
            name: 'Page Break',
            icon: require('@/assets/icons/page-break.svg'),
          },

          TrackerTableModule: {
            name: 'Tracker Table Module',
            route: 'pdf_report_tracker_table_editor',
            icon: require('@/assets/icons/tracker-table.svg'),
          },

          TableModule: {
            name: 'Table Module',
            route: 'pdf_report_table_editor',
            icon: require('@/assets/icons/table.svg'),
          },
        },
      }
    },

    mounted () {
      this.module = this.data

      if (!this.module.key) {
        this.module.key = uniqid()
      }
    },

    beforeDestroy () {},

    methods: {},
  }
</script>

<style lang="scss" scoped>
.add-module-below-section {
  height: 8px;
  cursor: copy;

  &:hover {
    background-color: #fff;
  }
}

.right {
  margin-left: auto;
}

.mini-view-item {
  width: 100%;
  padding: 10px;
  margin-bottom: 0;
  display: flex;
  border-radius: 4px;
  align-items: center;
  font-size: 15px;
  background-color: white;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
    0px 1px 2px 0px rgba(40, 39, 43, 0.06);
  img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  &.row {
    width: calc(100% - 60px);
    margin-left: auto;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    transition: background-color 0.1s;
    border: $border-1;
  }
  &.selected {
    background-color: $primary;
    color: white;
    img {
      filter: brightness(0) invert(1);
    }
  }
}
</style>
