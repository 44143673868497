<template>
  <div class="filter-config-editor">
    <b-field
      class="input-field"
      label="Filter">
      <FilterEditor v-model="inputs.filter" />
    </b-field>
    <b-field
      class="input-field mt-5"
      label="Filter time range (optional):">
      <TimeRangeEditor v-model="inputs.time_range" />
    </b-field>
    <div class="mt-5">
      <RepeatConfigEditor v-model="inputs.repeat_config" />
    </div>
    <b-field
      class="input-field mt-5"
      label="Offset calculation by (days):">
      <b-numberinput v-model="inputs.offset_days" />
    </b-field>
  </div>
</template>

<script>
  import FilterEditor from '@/components/reports/config/filters/FilterEditor'
  import ModuleConfigMixin from '@/components/reports/config/modules/common/ModuleConfigMixin'
  import RepeatConfigEditor from '@/components/reports/config/modules/common/RepeatConfigEditor'
  import TimeRangeEditor from '@/components/reports/config/modules/common/TimeRangeEditor'

  export default {
    name: 'FilterConfigEditor',
    components: {
      FilterEditor,
      TimeRangeEditor,
      RepeatConfigEditor,
    },
    mixins: [ModuleConfigMixin,],
  }
</script>

<style lang="scss" scoped>
.filter-config-editor {
  padding: 8px;
}
.benchmark-inputs {
  margin-top: 30px;
}
</style>
