<template>
  <div
    class="type-selector"
    :class="{ 'border-l': depth > 0 }"
    :style="{ borderColor: colorByPrevDepth }">
    <template v-if="['edit'].includes(mode)">
      <span
        class="mb-1 "
        style="font-size: 13px;">
        {{ objectType }} Type
      </span>
      <b-select
        v-model="type"
        :placeholder="placeholder"
        class="input-item w-full"
        expanded>
        <option
          v-for="typeOption in typeOptions"
          :key="typeOption.key"
          :value="typeOption">
          {{ typeOption.label }}
        </option>
      </b-select>
      <component
        :is="type.editor"
        v-if="typeOptions.includes(type)"
        v-model="vModel"
        class="mt-2"
        :context="context"
        v-bind="subObjectBinds" />
    </template>
    <template v-if="['summary'].includes(mode)">
      <div class="type-summary mt-2">
        {{ type.label }}:&nbsp;
        <component
          :is="type.editor"
          v-if="typeOptions.includes(type)"
          v-model="vModel"
          :context="context"
          v-bind="subObjectBinds" />
      </div>
    </template>
  </div>
</template>

<script>
  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'

  export default {
    name: 'TypeSelector',
    mixins: [ObjectEditorMixin,],
    props: {
      objectType: {
        type: String,
        required: true,
      },
      typeOptions: {
        type: Array,
        required: true,
      },
      context: {
        type: Object,
        default: Object,
      },
    },
    computed: {
      placeholder () {
        return `Select a ${this.objectType} Type`
      },
      byKey () {
        const byKey = {}
        this.typeOptions.forEach((type) => {
          byKey[type.key] = type
        })
        return byKey
      },
      type: {
        get () {
          return this.byKey[this.vModel?.type]
        },
        set (type) {
          if (type.default) {
            this.vModel = type.default()
          } else {
            this.vModel = {
              type: type.key,
            }
          }
        },
      },
    },
    mounted () {
      if (!this.type) {
        this.type = this.typeOptions[0]
      }
    },
  }
</script>

<style lang="scss" scoped>
.type-selector {
  display: flex;
  flex-direction: column;
}

.type-summary {
  display: flex;
  flex-direction: row;
}

.border-l {
  border-left: $border-1;
  padding: 20px;
  margin-left: 5px;
  // margin-top: 10px;
  // margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.02);
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
</style>
