<template>
  <div class="dimension-filter-editor w-full">
    <div class="ref-select w-full">
      <DimensionEditor
        v-model="dimension"
        v-bind="subObjectBinds" />
    </div>
    <div class="ref-select w-full">
      <DimensionFilterConditionEditor
        v-model="condition"
        v-bind="subObjectBinds"
        :dimension-values="dimensionValues"
        :loading-values="loadingValues" />
    </div>
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import DimensionFilterConditionEditor from '@/components/reports/config/filters/DimensionFilterConditionEditor'
  import { mapChildren } from '@/components/reports/config/common/objectUtil'

  export default {
    name: 'DimensionFilterEditor',
    components: {
      DimensionFilterConditionEditor,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    data () {
      return {
        dimensionValues: null,
        loadingValues: false,
      }
    },
    methods: {
      async loadDimensionValues (dimension) {
        this.loadingValues = true
        this.dimensionValues = null
        const values = await this.$apis.companyConfigs.getDimensionValues(dimension)
        if (this.dimension === dimension) {
          if (values) {
            this.dimensionValues = values
          }
          this.loadingValues = false
        }
      },
    },
    computed: {
      ...mapChildren({
        condition: 'condition',
      }),
      dimension: {
        get () {
          return this.vModel.dimension
        },
        set (dimension) {
          this.loadDimensionValues(dimension)
          this.vModel = {
            type: this.vModel.type,
            dimension,
          }
        },
      },
    },
    mounted () {
      this.loadDimensionValues(this.dimension)
    },
  }
</script>

<style lang="scss" scoped>
.dimension-filter-editor {
  display: flex;
  flex-direction: column;
}
.ref-select {
  display: flex;
  flex-direction: row;
}
</style>
