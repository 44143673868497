<template>
  <div class="nav-container">
    <div class="navbar">
      <div class="left">
        <img
          src="@/assets/images/ingest_logo.svg"
          class="logo"
          alt="" />
        <div v-if="hasMultipleCompanies">
          <company-changer />
        </div>
        <div
          v-else
          class="current-company">
          {{ companyName }}
        </div>
      </div>
      <div class="center">
        <!--=============================== Reports Links =============================-->
        <b-dropdown :triggers="['hover']">
          <template #trigger>
            <router-link
              class="parent-link"
              :to="navlinks.reports.route">
              {{ navlinks.reports.name }}
            </router-link>
          </template>

          <b-dropdown-item
            v-for="(childLink, j) in navlinks.reports.children"
            :key="j"
            class="nav-dropdown-item"
            aria-role="listitem">
            <router-link
              class="child-link"
              :to="childLink.route">
              {{ childLink.name }}
            </router-link>
          </b-dropdown-item>
        </b-dropdown>
        <!--=============================== Data Links =============================-->

        <b-dropdown
          v-if="childHasPermission(navlinks.data.children)"
          :triggers="['hover']">
          <template #trigger>
            <router-link
              class="parent-link"
              :to="navlinks.data.route">
              {{ navlinks.data.name }}
            </router-link>
          </template>

          <b-dropdown-item
            v-for="(childLink, j) in navlinks.data.children"
            :key="j"
            class="nav-dropdown-item"
            aria-role="listitem">
            <router-link
              class="child-link"
              :to="childLink.route">
              {{ childLink.name }}
            </router-link>
          </b-dropdown-item>
        </b-dropdown>

        <!--=============================== Tools Links =============================-->
        <b-dropdown
          v-if="childHasPermission(navlinks.tools.children)"
          :triggers="['hover']">
          <template #trigger>
            <router-link
              class="parent-link"
              :to="navlinks.tools.route">
              {{ navlinks.tools.name }}
            </router-link>
          </template>

          <b-dropdown-item
            v-for="(childLink, j) in navlinks.tools.children"
            :key="j"
            class="nav-dropdown-item"
            aria-role="listitem">
            <router-link
              class="child-link"
              :to="childLink.route">
              {{ childLink.name }}
            </router-link>
          </b-dropdown-item>
        </b-dropdown>

        <!--=============================== admin Links =============================-->
        <b-dropdown
          v-if="childHasPermission(navlinks.admin.children)"
          :triggers="['hover']">
          <template #trigger>
            <router-link
              class="parent-link"
              :to="navlinks.admin.route">
              {{ navlinks.admin.name }}
            </router-link>
          </template>

          <b-dropdown-item
            v-for="(childLink, j) in navlinks.admin.children"
            :key="j"
            class="nav-dropdown-item"
            aria-role="listitem">
            <router-link
              class="child-link"
              :to="childLink.route">
              {{ childLink.name }}
            </router-link>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="right">
        <div
          class="button action-item"
          @click="toggleSearch">
          <img
            src="@/assets/icons/search.svg"
            alt="" />
        </div>

        <b-dropdown
          :triggers="['hover']"
          position="is-bottom-left"
          aria-role="list">
          <template #trigger>
            <div
              class="button action-item"
              type="is-text">
              <img
                src="@/assets/icons/profile.svg"
                alt="" />
            </div>
          </template>
          <!--=============================== User Links =============================-->
          <b-dropdown-item
            v-for="(childLink, j) in navlinks.user.children"
            :key="j"
            class="account-link-item">
            <router-link
              class="account-link"
              :to="childLink.route">
              {{ childLink.name }}
            </router-link>
          </b-dropdown-item>

          <!-- <b-dropdown-item
            class="account-link-item progressier-install-button install-button"
            data-icons="false"
            data-install="Install Desktop App"
            data-installed="Launch the app" /> -->

          <b-dropdown-item
            class="account-link-item"
            @click="logout">
            <div class="account-link">
              Log Out
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="breadcrumbs">
      <b-breadcrumb>
        <b-breadcrumb-item
          v-for="(breadcrumb, i) in breadcrumbs"
          :key="i"
          tag="router-link"
          :class="{'backlink-breadcrumb': !(breadcrumb.routeName ? $route?.name === breadcrumb.routeName : true)}"
          :active="
            breadcrumb.routeName ? $route?.name === breadcrumb.routeName : true
          "
          :to="{ name: breadcrumb.routeName }">
          {{ breadcrumb.displayName }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <quick-search v-model="isSearchOpen" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CompanyChanger from '@/components/company/CompanyChanger'
  import QuickSearch from '@/components/navigation/QuickSearch'
  import navigation from '@/mixins/navigation'

  export default {
    name: 'NavBar',
    components: {
      CompanyChanger,
      QuickSearch,
    },
    mixins: [navigation,],
    data () {
      return {
        routerHierarchy: {},
        isSearchOpen: false,
      }
    },
    computed: {
      ...mapGetters({
        companyName: 'auth/user/companyName',
      }),
      allRoutes () {
        return this.$router.getRoutes()
      },
      breadcrumbs () {
        let  currentRoute = {
          displayName: this.$route.meta.displayName || this.$route.name,
        }
        const breadcrumbs = []

        if (this.$route.meta.breadcrumbs && this.$route.meta.breadcrumbs.length) {
          this.$route.meta.breadcrumbs.forEach((item) => {
            console.log(item, this.findRouteByName(item))
            const route = this.findRouteByName(item)
            if (!route) {
              return
            }
            breadcrumbs.push({
              routeName: route?.name,
              displayName: route?.meta?.displayName || route?.name,
            })
          })
        }

        breadcrumbs.push(currentRoute)
        return breadcrumbs
      },
    },
    methods: {
      toggleSearch () {
        this.isSearchOpen = !this.isSearchOpen
      },
      findRouteByName (name) {
        return this.allRoutes.find((item) => item.name === name)
      },
      childHasPermission (children) {
        return children.some((child) => {
          return child.permission
        })
      },
      logout () {
        //as soon as you log out the store throws errors for missing items.. so we need to catch the error
        try {
          this.$store.dispatch('auth/logout')
        } catch (e) {
          console.log(e)
        }
        this.$router.push({ name: 'login', })
      },
    },
  }
</script>
<style lang="scss" scoped>
$navbar-height: 55px;
$breadcrumbs-height: 40px;

.nav-container {
  height: calc($navbar-height + $breadcrumbs-height);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.navbar {
  height: $navbar-height;
  background-color: $grayscale-9;
  display: flex;
  align-items: center;
  padding: 0 25px;
}

.breadcrumbs {
  height: $breadcrumbs-height;
  background-color: #f8f8f8;
  padding: 0 25px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #727272;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.02);
  border-top: 1px solid rgba(0, 0, 0, 0.02);
}

.backlink-breadcrumb :deep(a:hover) {
  text-decoration: underline;
}

.left {
  display: flex;
  align-items: center;
  width: auto;
  .logo {
    height: 32px;
    width: auto;
    margin-right: 20px;
  }
  .current-company {
    font-size: 14px;
    border-radius: 9px;
  }
}

.center {
  margin: auto;
  display: flex;
  padding-right: 100px;
  .parent-link {
    color: $grayscale-2;
    font-size: 16px;
    padding: 0 20px;
    min-width: 100px;
    height: $navbar-height;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    border-bottom: 3px solid transparent;

    &.router-link-active {
      border-bottom-color: $primary;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  .nav-dropdown-item {
    display: flex;
    padding: 0 !important;
  }
  .child-link {
    min-width: 200px;
    text-transform: capitalize;
    color: #636363;
    height: 45px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 15px;
    &.router-link-active {
    }

    // &:hover{
    //  background-color: rgba(0,0,0,0.02);
    // }
  }
}

.right {
  display: flex;
  align-items: center;

  .action-item {
    border: $border-1;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin-left: 15px;
    padding: 0;
    transition: all 0.3s;

    img {
      width: 21px;
      height: 21px;
    }

    &:hover {
      border-color: $grayscale-3;
    }
  }
  .account-link-item {
    display: flex;
    width: 180px;
    color: #636363;
    padding: 0 !important;
    .account-link {
      height: 45px;
      color: #636363;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 15px;
    }

    &.install-button {
      border: none;
      padding: 15px 0 !important;
    }
  }
}

@media all and (display-mode: standalone) {
  .progressier-install-button {
    display: none !important;
  }
}
</style>
