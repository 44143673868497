<template>
  <div class="editor">
    <div class="block layout">
      <div class="block-header">
        <h4 class="">
          Rows
        </h4>
      </div>
      <div
        class="block-body">
        <Layout
          v-model="module"
          @open="openModule" />
      </div>
    </div>
    <div class="editor-modules">
      <DimensionEditor
        v-model="selectedModule"
        class="measure-editor" />
    </div>
  </div>
</template>

    <script>
  import Layout from './Layout.vue'
  import DimensionEditor from './DimensionEditor.vue'

  // import uniqid from 'uniqid'

  export default {
    name: 'TableRows',
    components: {
      Layout,
      DimensionEditor,
    },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
    },

    data () {
      return {
        selectedModule: null,
        selectedModuleType: '',

      }
    },

    computed: {
      module: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    mounted () {

    },

    beforeDestroy () {},

    methods: {
      removeReport (key) {
        this.$emit('remove', key)
      },
      openModule (selectedModule) {
        this.selectedModule = selectedModule
      },
      showModulePicker () {},

      makeInputsDirty () {},

      openTrackerRowModule (index = null) {
        if (index === null) {
          this.selectedRowModule = null
          this.selectedRowKey = null
          return
        }

        this.selectedRowModule = this.module.tracker_rows[index]
        this.selectedRowKey = this.selectedRowModule.key
      },

      switchOutputMode () {
        this.showCode = !this.showCode
      },
      getPDFfromBinary (data) {
        var blob = new Blob([data,], { type: 'application/pdf', })

        // create an object URL from the Blob
        var URL = window.URL || window.webkitURL
        URL.createObjectURL(blob)

        return URL.createObjectURL(blob)
      },

      // showModulePicker () {

      // },
    },
  }
    </script>

    <style lang="scss" scoped>
    .editor {
      width: 100%;
      height: 100%;
      margin: auto !important;
      background-color: white;
      display: flex;
      border: none;
      padding: 0;
    }

    .editor-modules{
      border: $border-1;
      border-radius: 3px;
      padding: 5px;
      width: 100%;
      height: 100%;

    }
    .editor-body {
      display: flex;
      height: 100%;
      overflow: hidden;
      border: none;
    }

    .block {
      border: $border-1;
      border-radius: 3px;
      height: 100%;
      flex-direction: column;
      display: flex;

      .block-header {
        min-height: 40px;
        align-items: center;
        padding: 0 5px;
        border-bottom: $border-1;
        background-color: white;
        display: flex;

        h4 {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          padding: 0 10px;
          text-transform: capitalize;
          margin: 0;
          color: black;
        }
      }
      .block-body {
        padding: 10px 10px;
        background-color: #fbfbfb;
        height: 100%;
        width: 100%;
        flex-direction: column;
        display: flex;
      }

      &.layout {
        max-height: 100%;
        overflow: hidden;
        margin-right: 10px;
        min-width: 300px;
      }

      &.editor {
        width: 100%;
      }

      &.preview {
        height: 100%;
        .block-header {
          width: 100%;
        }

        .block-body {
          width: 100%;
          height: 100%;
          display: flex;
          overflow: hidden;
        }

        .output {
          background-color: white;
          height: 100%;
          overflow: auto;
        }
      }
    }

    .editor-and-preview {
      width: 100%;
    }

    .editor-header {
      margin-bottom: 20px;

      display: flex;
      padding-top: 10px;
      .widget-type {
        display: flex;
        font-weight: 400;
        font-size: 15px;
        align-items: center;
        img {
          width: 35px;
          height: 35px;
          margin-right: 10px;
          border: $border-1;
          border-radius: 8px;
          padding: 5px;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      .controls {
        margin-left: auto;
        display: flex;
        align-items: center;

        .button {
          padding: 0 15px;
          font-size: 12px;
        }
      }
    }

    .mini-view-item {
        cursor: pointer;
        transition: background-color 0.3s ease-in-out, color 0.3s;
        width: 100%;
        padding: 10px;
        display: flex;
        border-radius: 4px;
        align-items: center;
        font-size: 15px;
        background-color: white;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
          0px 1px 2px 0px rgba(40, 39, 43, 0.06);

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
          transition: background-color 0.1s;
          border: $border-1;
        }
        &.selected {
          background-color: $primary;
          color: white;
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    </style>
