var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor-container"},[_c('div',{staticClass:"editor-header"},[_c('div',{staticClass:"widget-type"},[_c('router-link',{attrs:{"to":{ name: 'pdf_report_editor', params: { report_id: _vm.reportId } }}},[_c('b-button',{staticClass:"back-btn mr-5",attrs:{"type":"is-light","icon-right":"arrow-left"}})],1),_c('div',{staticClass:"report-breadcrumbs"},[_c('div',[_c('h4',[_vm._v("Report")]),_c('h2',[_vm._v(_vm._s(_vm.reportData?.name || "Pdf Report"))])]),_vm._m(0),_c('div',[_c('h4',[_vm._v("Module")]),_c('h2',[_vm._v(_vm._s(_vm.module?.title || _vm.moduleConfigs.name))])])])],1),_c('div',{staticClass:"controls"},[_c('a-button',{staticClass:"button is-light",attrs:{"type":"button ml-2","disabled":!_vm.isDirty,"hotkeys":"ctrl+shift+d"},on:{"click":_vm.getData}},[_vm._v(" Discard Changes ")]),_c('a-button',{staticClass:"button is-primary",attrs:{"type":"button ml-2","disabled":!_vm.isDirty,"hotkeys":"ctrl+shift+s"},on:{"click":_vm.save}},[_vm._v(" Publish Changes & Close ")])],1)]),_c('div',{staticClass:"editor-body"},[_c('div',{staticClass:"block layout"},[_vm._m(1),(_vm.module)?_c('div',{staticClass:"block-body"},[_c('b-tooltip',{staticStyle:{"width":"100%"},attrs:{"position":"is-right","type":"is-light","append-to-body":"true","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"data-tooltip"},[_c('div',[_c('h2',[_vm._v("Time Dimension:")]),_vm._v(" "+_vm._s(_vm.module?.time_dimension?.name || _vm.module?.time_dimension?.key || "Null")+" ")]),(_vm.module?.tracker_pivot)?_c('div',[_c('h2',[_vm._v("Pivot:")]),_vm._v(" "+_vm._s(_vm.module?.tracker_pivot?.dimension?.name || _vm.module?.tracker_pivot?.dimension?.key || "Null")+" ")]):_vm._e()])]},proxy:true}],null,false,3740384068)},[_c('div',{staticClass:"row mini-view-item",class:{
              selected: !_vm.selectedRowKey,
              'error-border': _vm.module.errors,
            },on:{"click":function($event){return _vm.openTrackerRowModule(null)}}},[_c('img',{attrs:{"src":_vm.moduleConfigs.icon,"alt":""}}),_c('div',[_vm._v(" "+_vm._s(_vm.module?.title || _vm.moduleConfigs.name)+" ")])])]),(!_vm.isLoading && _vm.module)?_c('MiniTrackerTable',{key:_vm.module.key,attrs:{"current-selected-key":_vm.selectedRowKey},on:{"open":_vm.openTrackerRowModule},model:{value:(_vm.module),callback:function ($$v) {_vm.module=$$v},expression:"module"}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"editor-and-preview"},[_c('splitpanes',{staticClass:"default-theme"},[_c('pane',{staticClass:"pane",attrs:{"min-size":"20"}},[_c('div',{staticClass:"block editor"},[(_vm.selectedRowKey)?_c('TrackerRow',{attrs:{"context":{
                timeDimension: _vm.module?.time_dimension,
                pivotDimension: _vm.module?.tracker_pivot?.dimension,
              }},model:{value:(_vm.selectedRowModule),callback:function ($$v) {_vm.selectedRowModule=$$v},expression:"selectedRowModule"}}):_c('TrackerTableEditor',{key:_vm.module?.key,model:{value:(_vm.module),callback:function ($$v) {_vm.module=$$v},expression:"module"}})],1)]),_c('pane',{attrs:{"min-size":"20"}},[_c('PreviewAndJSONEditor',{attrs:{"type":"module"},model:{value:(_vm.module),callback:function ($$v) {_vm.module=$$v},expression:"module"}})],1)],1)],1)]),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arrow-right"},[_c('i',{staticClass:"material-icons"},[_vm._v("chevron_right ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-header"},[_c('h4',{},[_vm._v(" Modules ")])])
}]

export { render, staticRenderFns }