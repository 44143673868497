<template>
  <div
    v-if="inputs"
    class="row-editor-container">
    <div class="editor-header">
      <div class="widget-type">
        <i class="material-icons mr-2"> functions </i>
        <div>{{ inputs.name || moduleConfigs.name }}</div>
      </div>
      <Tabs
        v-model="inputSectionTab"
        :is-objects="true"
        :tabs="tabs" />
    </div>

    <div class="editor">
      <div
        v-if="inputSectionTab === 'display-label'"
        class="sub-section">
        <b-field
          label="Display Label"
          class="input-field"
          message="">
          <b-input
            v-model="inputs.name"
            type="" />
        </b-field>
      </div>

      <div
        v-if="inputSectionTab === 'dimension'"
        class="sub-section">
        <DimensionTab v-model="inputs.dimension" />
      </div>
      <div
        v-if="inputSectionTab === 'order'"
        class="sub-section">
        <OrderConfigEditor v-model="inputs.order" />
      </div>
      <div
        v-if="inputSectionTab === 'filters'"
        class="sub-section">
        <FiltersTab v-model="inputs.filters" />
      </div>
      <div
        v-if="inputSectionTab === 'format'"
        class="sub-section">
        <DataFormatEditor v-model="inputs.data_format" />
      </div>

      <div
        v-if="inputSectionTab === 'total-rows'"
        class="sub-section">
        <TotalRowsTab v-model="inputs.table_totals" />
      </div>
      <div
        v-if="inputSectionTab === 'header-style'"
        class="sub-section">
        <StyleTab v-model="inputs.header_style">
          <div class="mb-2">
            <b-field
              label="Column Width"
              class="input-field"
              message="">
              <b-numberinput
                v-model="inputs.column_width"
                min="0" />
            </b-field>
          </div>
        </StyleTab>
      </div>

      <div
        v-if="inputSectionTab === 'cell-style'"
        class="sub-section">
        <StyleTab
          key="datastyle"
          v-model="inputs.data_style" />
      </div>
    </div>
  </div>
</template>

  <script>
  import Tabs from '@/components/pdf_report/Tabs.vue'

  // import MeasureEditorMeasureTab from './MeasureEditorMeasureTab.vue'
  import DimensionTab from './DimensionTab.vue'
  import OrderConfigEditor from '@/components/reports/config/modules/common/OrderConfigEditor'
  import FiltersTab from './FiltersTab.vue'
  import StyleTab from './StyleTab.vue'
  import DataFormatEditor from '@/components/reports/config/modules/common/DataFormatEditor'
  import TotalRowsTab from './TotalRowsTab.vue'
  // import MeasureEditorBenchmarkTab from './MeasureEditorBenchmarkTab.vue'

  export default {
    name: 'DimensionEditor',
    components: {
      Tabs,
      DataFormatEditor,
      DimensionTab,
      FiltersTab,
      TotalRowsTab,
      StyleTab,
      OrderConfigEditor,

    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        moduleConfigs: {
          name: 'Measure',
          hideInLibrary: true,
        },

        inputSectionTab: 'display-label',
      }
    },

    computed: {
      tabs () {
        return [
          {
            label: 'Display Label',
            id: 'display-label',
            hasErrors: false,
          },
          {
            label: 'Dimension',
            id: 'dimension',
            hasErrors: this.inputs?.dimension?.errors,
          },
          {
            label: 'Order',
            id: 'order',
            hasErrors: this.inputs?.order?.errors,
          },
          {
            label: 'Filters',
            id: 'filters',
            hasErrors: this.inputs?.filters?.some((f) => f.errors),
          },
          {
            label: 'Format',
            id: 'format',
            hasErrors: this.inputs?.data_format?.errors,
          },
          {
            label: 'Total Rows',
            id: 'total-rows',
            hasErrors: this.inputs?.table_totals?.some((f) => f.errors),
          },
          {
            label: 'Header Style',
            id: 'header-style',
            hasErrors: this.inputs?.header_style?.errors,
          },
          {
            label: 'Cell Style',
            id: 'cell-style',
            hasErrors: this.inputs?.data_style?.errors,
          },
        ]
      },
      inputs: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    async mounted () {},

    beforeDestroy () {},

    methods: {},
  }
  </script>

  <style lang="scss" scoped>
  .editor-header {
    border-bottom: $border-1;
    padding: 20px;
    background: #fff;

    .widget-type {
      display: flex;
      font-weight: 400;
      font-size: 18px;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        border: $border-1;
        border-radius: 8px;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .row-editor-container {
    width: 100%;
    height: 100%;
    margin: auto !important;
    background: #fbfbfb;
    display: flex;
    flex-direction: column;
    padding: 0px;
  }

  .editor {
    padding: 20px;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .sub-section {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      max-width: 100%;
      overflow: auto;
    }
  }
  // .card {
  //   border: $border-1;

  //   .card-header-title {
  //     margin-bottom: 0 !important;
  //   }
  // }
  // .input-field {
  //   max-width: 400px;
  // }

  // h3.sub-section-title {
  //   margin-bottom: 12px;
  //   font-weight: 500;
  //   font-size: 17px;
  //   // margin-left: -50px;
  // }
  </style>
