<template>
  <div class="editor-container">
    <div class="editor-header">
      <div class="widget-type">
        <router-link
          :to="{ name: 'pdf_report_editor', params: { report_id: reportId } }">
          <b-button
            type="is-light"
            class="back-btn mr-5"
            icon-right="arrow-left" />
        </router-link>
        <div class="report-breadcrumbs mr-6">
          <div>
            <h4>Report</h4>
            <h2>{{ reportData?.name || "Pdf Report" }}</h2>
          </div>

          <div class="arrow-right">
            <i class="material-icons">chevron_right </i>
          </div>

          <div>
            <h4>Module</h4>
            <h2>{{ module?.title || moduleConfigs.name }}</h2>
          </div>
        </div>
        <Tabs
          v-model="tabSelected"
          :is-objects="true"
          :tabs="tabs" />
      </div>

      <div class="controls">
        <a-button
          type="button ml-2"
          :disabled="!isDirty"
          hotkeys="ctrl+shift+d"
          class="button is-light"
          @click="getData">
          Discard Changes
        </a-button>
        <a-button
          type="button ml-2"
          :disabled="!isDirty"
          hotkeys="ctrl+shift+s"
          class="button is-primary"
          @click="save">
          Publish Changes & Close
        </a-button>
      </div>
    </div>
    <div class="editor-body">
      <div class="editor-and-preview">
        <splitpanes class="default-theme">
          <pane
            min-size="40"
            class="pane">
            <TableSettings
              v-if="tabSelected === 'table'"
              v-model="module" />
            <TableCols
              v-if="tabSelected === 'columns'"
              v-model="module.measure_groups"
              :context="{
                reportDimensions,
              }" />
            <TableRows
              v-if="tabSelected === 'rows'"
              v-model="module.table_dimensions" />
          </pane>
          <pane min-size="40">
            <PreviewAndJSONEditor
              v-model="module"
              type="module" />
          </pane>
        </splitpanes>
      </div>
    </div>
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="false" />
  </div>
</template>

<script>
  import TableSettings from '@/components/pdf_report/table_module/table_settings/TableSettings'
  import TableRows from '@/components/pdf_report/table_module/table_rows/TableRows'
  import TableCols from '@/components/pdf_report/table_module/table_cols/TableCols'
  import uniqid from 'uniqid'

  import Tabs from '@/components/pdf_report/Tabs.vue'

  import { Splitpanes, Pane } from 'splitpanes'

  import PreviewAndJSONEditor from '@/components/pdf_report/PreviewAndJsonEditor.vue'

  export default {
    name: 'TableEditor',
    components: {
      TableSettings,
      TableRows,
      TableCols,
      Splitpanes,
      Pane,
      Tabs,
      PreviewAndJSONEditor,
    },
    beforeRouteLeave (from, to, next) {
      if (this.isDirty) {
        this.$buefy.dialog.confirm({
          title: 'Unsaved Changes',
          message: 'Are you sure you want to discard changes and leave page?',
          onConfirm: () => next(),
        })
      } else {
        next()
      }
    },
    props: {},
    data () {
      return {
        defaultTimeRange: {
          include_today: false,
          key: '',
          name: '',
          type: '',
        },
        moduleId: null,
        reportId: null,
        module: null,
        reportData: null,

        tabSelected: 'table',

        moduleConfigs: {
          name: 'Table',
          type: 'TableModule',
          icon: require('@/assets/icons/table.svg'),
        },

        selectedRowKey: null,
        selectedRowModule: null,

        isLoading: true,
        isDirty: false,
      }
    },

    computed: {
      reportDimensions () {
        return this.module.table_dimensions.map((td) => td.dimension)
      },

      tabs () {
        return [
          {
            label: 'Table',
            id: 'table',
            hasErrors: this.module?.errors,
          },
          {
            label: 'Columns',
            id: 'columns',
            hasErrors: this.module?.measure_groups.some((item) => item.errors),
          },
          {
            label: 'Rows',
            id: 'rows',
            hasErrors: this.module?.table_dimensions.some((item) => item.errors),
          },
        ]
      },
    },

    watch: {
      module: {
        handler () {
          this.isDirty = true
        },
        deep: true,
      },
    },

    async mounted () {
      this.moduleId = this.$route.params.module_id
      this.reportId = this.$route.params.report_id
      await this.getData()
      this.isLoading = false
      if (!this.module.key) {
        this.module.key = uniqid()
      }

      await this.$store.dispatch('app/reportsConfig/refreshOptions')
    },
    methods: {
      async getData () {
        this.isLoading = true
        const response = await this.$apis.pdfReports.getPdfModuleData(
          this.moduleId
        )
        this.module = response

        this.reportData = await this.$apis.pdfReports.getPdfReportData(
          this.reportId
        )
        await this.$nextTick()
        await this.$nextTick()
        await this.$nextTick()
        this.isDirty = false
        this.isLoading = false
      },

      removeReport (key) {
        this.$emit('remove', key)
      },

      onError (error) {
        console.log('error', error)
      },

      showModulePicker () {},

      makeInputsDirty () {},

      async save () {
        const response = await this.$apis.pdfReports.updatePdfReportModule(
          this.module
        )

        if (!response) {
          this.$buefy.toast.open({
            message: 'Backend Error, Report this to the devs',
            type: 'is-warning',
          })
          return
        }

        if (response?.errors) {
          this.$buefy.toast.open({
            message: 'Error saving module, Fix the errors and try again',
            type: 'is-warning',
          })

          this.module = {}
          await this.$nextTick()
          await this.$nextTick()
          await this.$nextTick()
          this.module = response
        } else {
          this.module = {}
          await this.$nextTick()
          await this.$nextTick()
          await this.$nextTick()
          this.module = response
          this.$buefy.toast.open({
            message: 'Changes Saved',
            type: 'is-success',
            position: 'is-bottom',
          })
          await this.$nextTick()
          await this.$nextTick()
          await this.$nextTick()
          this.isDirty = false

          this.$router.push({
            name: 'pdf_report_editor',
            params: { report_id: this.reportId, },
          })
        }
      },

      async deleteModule () {
        this.isLoading = true

        if (await this.$apis.pdfReports.deletePdfReportModule(this.moduleId)) {
          this.$router.push({
            name: 'pdf_report_editor',
            params: { report_id: this.reportId, },
          })
        } else {
          this.$buefy.toast.open({
            message: 'Error deleting module',
            type: 'is-danger',
            position: 'is-bottom',
          })
        }
        this.isLoading = true
      },

      openTrackerRowModule (index = null) {
        if (index === null) {
          this.selectedRowModule = null
          this.selectedRowKey = null
          return
        }

        this.selectedRowModule = this.module.tracker_rows[index]
        this.selectedRowKey = this.selectedRowModule.key
      },
    },
  }
</script>

<style lang="scss" scoped>
.report-breadcrumbs {
  display: flex;

  h2 {
    font-weight: 400;
    font-size: 20px;
    margin: 0;
  }

  h4 {
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    color: $grayscale-3;
  }

  .arrow-right {
    display: flex;
    align-items: center;
    margin: 0 10px;
    font-size: 20px;
    color: #b0b0b0;
  }
}
.back-btn {
  font-size: 20px !important;
  border-radius: 100% !important;
}
.editor-container {
  padding: 0 20px 5px;
  width: 100%;
  height: 100%;
  margin: auto !important;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.editor-body {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.pane {
  background-color: transparent;
  border: none;
}
.block {
  border: $border-1;
  border-radius: 3px;
  height: 100%;
  flex-direction: column;
  display: flex;

  .block-header {
    min-height: 40px;
    align-items: center;
    padding: 0 5px;
    border-bottom: $border-1;
    background-color: white;
    display: flex;

    h4 {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      padding: 0 10px;
      text-transform: capitalize;
      margin: 0;
      color: black;
    }
  }
  .block-body {
    padding: 10px 10px;
    background-color: #fbfbfb;
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  &.layout {
    max-height: 100%;
    overflow: hidden;
    margin-right: 10px;
    width: 300px;
  }

  &.editor {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &.preview {
    height: 100%;
    .block-header {
      width: 100%;
    }

    .block-body {
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
    }

    .output {
      background-color: white;
      height: 100%;
      overflow: auto;
    }
  }
}

.editor-and-preview {
  width: 100%;
}

.editor-header {
  margin-bottom: 20px;

  display: flex;
  padding-top: 10px;
  .widget-type {
    display: flex;
    font-weight: 400;
    font-size: 15px;
    align-items: center;
    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      border: $border-1;
      border-radius: 8px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .controls {
    margin-left: auto;
    display: flex;
    align-items: center;

    .button {
      padding: 0 15px;
      font-size: 12px;
    }
  }
}

.mini-view-item {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s;
  width: 100%;
  padding: 10px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  font-size: 15px;
  background-color: white;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
    0px 1px 2px 0px rgba(40, 39, 43, 0.06);

  img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    transition: background-color 0.1s;
    border: $border-1;
  }
  &.selected {
    background-color: $primary;
    color: white;
    img {
      filter: brightness(0) invert(1);
    }
  }
}
</style>
