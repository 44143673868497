<template>
  <div
    class="dimension-filter-condition-editor"
    :class="{ 'border-l': depth > 0 }"
    :style="{ borderColor: colorByPrevDepth }">
    <TypeSelector
      v-model="vModel"
      object-type="Dimension Filter Condition"
      v-bind="subObjectBinds"
      :context="{
        dimensionValues,
        loadingValues,
      }"
      :type-options="conditionTypes" />
  </div>
</template>

<script>

  import DimensionInConditionEditor from '@/components/reports/config/filters/DimensionInConditionEditor'
  import DimensionEqConditionEditor from '@/components/reports/config/filters/DimensionEqConditionEditor'
  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import TypeSelector from '@/components/reports/config/common/TypeSelector'

  export default {
    name: 'FilterEditor',
    components: {
      TypeSelector,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    props: {
      dimensionValues: {
        type: Array,
        default: null,
      },
      loadingValues: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        conditionTypes: [
          {
            key: 'DimensionInCondition',
            label: 'Is In',
            editor: DimensionInConditionEditor,
            default: () => ({
              type: 'DimensionInCondition',
              key_values: [],
            }),
          },
          {
            key: 'DimensionEqCondition',
            label: 'Equals',
            editor: DimensionEqConditionEditor,
          },
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
.dimension-filter-condition-editor {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
