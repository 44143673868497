<template>
  <div class="">
    <div
      v-if="trackerRowTotals.length"
      class="items-container">
      <vuedraggable
        group="moduleCards"
        ghost-class="ghost"
        class="dropzone"
        :list="trackerRowTotals">
        <div
          v-for="(row, i) in trackerRowTotals"
          :key="i"
          class="tracker-row-item">
          <TrackerRowTotal
            v-model="trackerRowTotals[i]"
            :context="context"
            @delete="removeRow(i)" />
        </div>
      </vuedraggable>
    </div>
    <!-- <button
      class="button add-row-button"
      @click.stop="addRow">
      <img
        src="@/assets/icons/add-row.svg"
        alt="" />

      <div>Add row</div>
    </button> -->
  </div>
</template>

<script>
  import TrackerRowTotal from '@/components/pdf_report/tracker_table_module/TrackerRowTotal.vue'
  import vuedraggable from 'vuedraggable'
  export default {
    name: 'TrackerRowTotals',
    components: {
      TrackerRowTotal,
      vuedraggable,
    },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      context: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {}
    },
    computed: {
      trackerRowTotals: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    mounted () {},

    beforeDestroy () {},
    methods: {
      removeRow (index) {
        this.trackerRowTotals.splice(index, 1)
      },
      // addRow () {
      //   this.trackerRowTotals.push({
      //     measure_config: {
      //       measure: {
      //         type: 'CompanyMeasureRef',
      //         key: 'forecasted_net_sales',
      //         name: '',
      //       },
      //     },
      //     conditional_format: null,
      //     data_format: {
      //       data_type: 'money',
      //       show_commas: true,
      //       decimal_places: 2,
      //       parenthesize_negatives: false,
      //     },
      //   })
      // },
    },
  }
</script>

<style lang="scss" scoped>

.items-container {
  background-color: #fbfbfb;
  padding: 5px 15px;

  border: $border-1;
  border-radius: 10px;

  height: 100%;
  display: flex;
  flex-direction: column;

  overflow: auto;
}

.add-row-button {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  justify-content: center;
  color: $primary !important;
  border-radius: 4px;
  border: 1px solid rgba(0, 140, 132, 0.1) !important;
  background: rgba(25, 207, 198, 0.05) !important;
  justify-content: flex-start;

  img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  &:hover {
    color: $primary;
    border: 1px solid rgba(0,0,0,0.2)
  }
}
</style>
