import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  authedMiddleware,
  redirectToDefaultRoute
} from '@/service/auth/authService'

/*================================ Layouts ==============================*/
import DashboardLayout from '@/layouts/DashboardLayout'
import AuthLayout from '@/layouts/AuthLayout'
/*================================ Pages ==============================*/

/*======= SETTINGS =======*/
import MyProfile from '@/views/settings/MyProfile'

/*==== END OF SETTINGS ====*/

/*======= TOOLS =======*/
import ToolsHome from '@/views/tools/index'
import ModuleLibrary from '@/views/tools/module_configs/ModuleLibrary'
import ModuleEditor from '@/views/tools/module_configs/ModuleEditor'
import EmailReportLibrary from '@/views/tools/email_reports/EmailReportLibrary'
import EmailEditor from '@/views/tools/email_reports/EmailEditor'
import EmailSendHistory from '@/views/tools/email_reports/EmailSendHistory'
import ReportEditor from '@/views/tools/email_reports/ReportEditor'
import PdfReportLibrary from '@/views/tools/pdf_reports/PdfReportLibrary'
import PdfReportTableModuleEditor from '@/views/tools/pdf_reports/Modules/TableEditor/TableEditor'
import PdfReportTrackerTableModuleEditor from '@/views/tools/pdf_reports/Modules/TrackerTableEditor/TrackerTableEditor'
import PdfReportSectionDividerEditor from '@/views/tools/pdf_reports/Modules/SectionDividerEditor/SectionDividerEditor'
import PdfReportTitleModuleEditor from '@/views/tools/pdf_reports/Modules/TitleModuleEditor/TitleModuleEditor'
import PdfReportEditor from '@/views/tools/pdf_reports/PdfReportEditor'
import PdfReportSettings from '@/views/tools/pdf_reports/PdfReportSettings'
import ChequeSearch from '@/views/tools/ChequeSearch'
import ChequeSearchNew from '@/views/tools/ChequeSearchNew'
import Expression from '@/views/tools/expressions/Expressions'
import ReportsConfig from '@/views/tools/reports_config/ReportsConfig'
import ConfigVisualizer from '@/components/reports/debug/ConfigVisualizer'
import ExpressionPrototyper from '@/views/tools/expressions/ExpressionPrototyper'

import CompanySettings from '@/views/tools/CompanySettings'
import LocationSettings from '@/views/tools/LocationSettings'
/*==== END OF TOOLS ====*/

/*======= ADMIN =======*/
import AdminHome from '@/views/admin/index'
import UsersLibrary from '@/views/admin/UsersLibrary'
import UserEditor from '@/views/admin/UserEditor'
import RolesLibrary from '@/views/admin/RolesLibrary'
import RoleEditor from '@/views/admin/RoleEditor'

/*==== END OF ADMIN ====*/

/*======= INTERNAL =======*/
import ComponentLibrary from '@/views/internal/components/index'
import GridBuilder from '@/views/internal/components/Grid'
import RenderForm from '@/views/internal/components/RenderForm'
import RenderModule from '@/views/internal/components/RenderModule'
import DatePickers from '@/views/internal/components/Datepickers'
import ViewDag from '@/views/internal/components/ViewDag'
/*==== END OF INTERNAL ====*/

/*======= DATA =======*/
import DataHome from '@/views/data/index'
import Integrations from '@/views/data/integrations/index'
import ForecastUploader from '@/views/data/ForecastUploader'
import AttributesHome from '@/views/data/attributes/AttributesHome'
import ManageAttributes from '@/views/data/attributes/ManageAttributes'
import ManageConsolidatedValues from '@/views/data/attributes/ManageConsolidatedValues'

import EditAttribute from '@/views/data/attributes/EditAttribute'

import IntegrationsRedirect from '@/views/data/integrations/IntegrationsRedirect'
/*==== END OF DATA ====*/

/*======= REPORTS =======*/
import ReportsHome from '@/views/reports/index'
import DashboardNavigator from '@/views/reports/DashboardNavigator'
import DashboardV2 from '@/views/reports/DashboardV2' //what dave is working on
import Dashboard from '@/views/reports/dashboard/index' //what akmar is working on

/*==== END OF REPORTS ====*/

/*======= MISC =======*/
import NotFound from '@/views/misc/404Page'
/*==== END OF MISC ====*/

/*======= AUTH =======*/
import PasswordReset from '@/views/auth/PasswordReset'
import CreateUser from '@/views/auth/CreateUser'
import LoginPage from '@/views/auth/LoginPage'
/*======= END OF AUTH =======*/

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    beforeEnter: redirectToDefaultRoute,
  },
  {
    path: '/company/:company_id',
    component: DashboardLayout,
    beforeEnter: authedMiddleware,
    children: [
      {
        path: '/',
        beforeEnter: redirectToDefaultRoute,
      },

      {
        path: 'profile',
        name: 'my_profile',
        meta: {
          displayName: 'My Profile',
        },
        component: MyProfile,
      },

      /*======= REPORTS =======*/
      {
        name: 'reports',
        path: 'reports',
        component: ReportsHome,
        meta: {
          displayName: 'Reports',
        },
      },
      {
        name: 'report',
        path: 'reports/:dashboard_id',
        component: DashboardNavigator,
        meta: {
          param: 'dashboard_id',
          breadcrumbs: ['reports',],
        },
      },
      {
        name: 'v2report',
        path: 'v2reports/:id',
        component: DashboardV2,
        meta: {
          param: 'id',
          breadcrumbs: ['reports',],
        },
      },

      {
        name: 'dashboard',
        path: 'dashboard/:id',
        component: Dashboard,
        meta: {
          param: 'id',
          breadcrumbs: ['reports',],
        },
      },

      /*======= DATA =======*/
      {
        name: 'data',
        path: 'data',
        component: DataHome,
        meta: {
          displayName: 'Data',
        },
      },
      {
        name: 'attributes',
        path: 'data/entity_attributes',
        component: AttributesHome,
        meta: {
          displayName: 'Attributes',
          breadcrumbs: ['data',],
        },
      },
      {
        name: 'manage_attributes',
        path: 'data/attributes/manage/:entity_type',
        component: ManageAttributes,
        meta: {
          displayName: 'Manage Attributes',
          breadcrumbs: ['data', 'attributes',],
        },
      },
      {
        name: 'manage_consolidated_values',
        path: 'data/attributes/consolidated_values/:entity_type',
        component: ManageConsolidatedValues,
        meta: {
          displayName: 'Manage Attributes',
          breadcrumbs: ['data', 'attributes',],
        },
      },
      {
        name: 'edit_attribute',
        path: 'data/attributes/manage/:entity_type/:attribute_id',
        component: EditAttribute,
        meta: {
          displayName: 'Manage Attributes',
          breadcrumbs: ['data', 'attributes',],
        },
      },

      {
        name: 'forecast_uploader',
        path: 'data/forecast_uploader',
        component: ForecastUploader,
        meta: {
          displayName: 'Upload Forecasts',
          breadcrumbs: ['data',],
        },
      },
      {
        name: 'integrations_page',
        path: 'data/integrations',
        component: Integrations,
        meta: {
          displayName: 'Integrations',
          breadcrumbs: ['data',],
        },
      },
      /*======= TOOLS =======*/
      {
        name: 'tools',
        path: 'tools',
        component: ToolsHome,
        meta: {
          displayName: 'Tools',
        },
      },
      {
        path: 'tools/checks',
        name: 'checks',
        component: ChequeSearch,
        meta: {
          displayName: 'Checks',
          breadcrumbs: ['tools',],
        },
        children: [
          {
            name: 'check',
            path: ':cheque_id',
            component: ChequeSearch,
            meta: {
              param: 'cheque_id',
              breadcrumbs: ['tools', 'checks',],
            },
          },
        ],
      },
      {
        path: 'tools/checks_new',
        name: 'checksNew',
        component: ChequeSearchNew,
        meta: {
          displayName: 'Checks',
          breadcrumbs: ['tools',],
        },
        children: [
          {
            name: 'checkNewItem',
            path: ':cheque_id',
            component: ChequeSearchNew,
            meta: {
              param: 'cheque_id',
              breadcrumbs: ['tools', 'checks',],
            },
          },
        ],
      },
      {
        path: 'tools/expression_prototyper',
        component: ExpressionPrototyper,
        name: 'expression_prototyper',
        meta: {
          displayName: 'Expression Prototyper',
          breadcrumbs: ['tools',],
        },
      },
      {
        name: 'expressions',
        path: 'tools/expressions',
        component: Expression,
        meta: {
          displayName: 'Expressions',
          breadcrumbs: ['tools',],
        },
      },
      {
        name: 'reports_config',
        path: 'tools/reports/config',
        component: ReportsConfig,
        meta: {
          displayName: 'Reports Configuration',
          breadcrumbs: ['tools',],
        },
      },
      {
        name: 'reports_config_visulizer',
        path: 'tools/reports/config/visualize',
        component: ConfigVisualizer,
        meta: {
          displayName: 'Visualize Reports Configuration',
          breadcrumbs: ['tools',],
        },
      },
      {
        name: 'email_report_library',
        path: 'tools/email_reports',
        component: EmailReportLibrary,
        meta: {
          displayName: 'Email Reports',
          breadcrumbs: ['tools',],
        },
      },
      {
        name: 'email_editor',
        path: 'tools/email_reports/:report_id',
        component: EmailEditor,
        meta: {
          param: 'report_id',
          displayName: 'Email Editor',
          breadcrumbs: ['tools', 'email_report_library',],
        },
      },
      {
        name: 'email_history',
        path: 'tools/email_report_history',
        component: EmailSendHistory,
        meta: {
          displayName: 'Email History',
          breadcrumbs: ['tools', 'email_report_library',],
        },
      },
      {
        name: 'report_editor',
        path: 'tools/reports/:report_id',
        component: ReportEditor,
        meta: {
          param: 'report_id',
          breadcrumbs: ['tools', 'email_report_library',],
        },
      },
      {
        name: 'email_report_builder',
        path: 'tools/email_report_builder',
        component: PdfReportLibrary,
        meta: {
          displayName: 'Email Report Builder',
          breadcrumbs: ['tools',],
        },
      },
      {
        name: 'pdf_report_editor',
        path: 'tools/email_report_builder/:report_id',
        component: PdfReportEditor,
        meta: {
          displayName: 'Report',
          param: 'report_id',
          breadcrumbs: ['tools', 'email_report_builder',],
        },
      },

      {
        name: 'pdf_report_table_editor',
        displayName: 'Table',
        path: 'tools/email_report_builder/:report_id/table_module/:module_id',
        component: PdfReportTableModuleEditor,
        meta: {
          displayName: 'Table',
          param: 'module_id',
          breadcrumbs: ['tools', 'email_report_builder', 'pdf_report_editor',],
        },
      },
      {
        name: 'pdf_report_title_module_editor',
        displayName: 'Title',
        path: 'tools/email_report_builder/:report_id/title_module/:module_id',
        component: PdfReportTitleModuleEditor,
        meta: {
          displayName: 'Title',
          param: 'module_id',
          breadcrumbs: ['tools', 'email_report_builder', 'pdf_report_editor',],
        },
      },
      {
        name: 'pdf_report_section_divider_editor',
        path: 'tools/email_report_builder/:report_id/section_divider_module/:module_id',
        component: PdfReportSectionDividerEditor,
        meta: {
          displayName: 'Divider',
          param: 'module_id',
          breadcrumbs: ['tools', 'email_report_builder', 'pdf_report_editor',],
        },
      },

      {
        name: 'pdf_report_tracker_table_editor',
        path: 'tools/email_report_builder/:report_id/tracker_table_module/:module_id',
        component: PdfReportTrackerTableModuleEditor,
        meta: {
          displayName: 'Tracker Table',
          param: 'module_id',
          breadcrumbs: ['tools', 'email_report_builder', 'pdf_report_editor',],
        },
      },

      {
        name: 'pdf_report_settings',
        path: 'tools/email_report_builder/:report_id/settings',
        component: PdfReportSettings,
        meta: {
          displayName: 'Report Settings',
          param: 'report_id',
          breadcrumbs: ['tools', 'email_report_builder', 'pdf_report_editor',],
        },
      },

      /*======= ADMIN =======*/
      {
        name: 'admin',
        path: 'admin',
        component: AdminHome,
        meta: {
          displayName: 'Admin',
        },
      },
      {
        name: 'users',
        path: 'admin/users',
        component: UsersLibrary,
        meta: {
          displayName: 'Users',
          breadcrumbs: ['admin',],
        },
      },

      {
        name: 'create_user',
        path: 'admin/users/new',
        component: UserEditor,
        meta: {
          displayName: 'Create User',
          breadcrumbs: ['admin', 'users',],
        },
      },

      {
        name: 'edit_user',
        path: 'admin/users/:user_id',
        component: UserEditor,
        meta: {
          displayName: 'Edit User',
          breadcrumbs: ['admin', 'users',],
        },
      },

      {
        name: 'roles',
        path: 'admin/roles',
        component: RolesLibrary,
        meta: {
          displayName: 'Roles',
          breadcrumbs: ['admin',],
        },
      },
      {
        name: 'create_role',
        path: 'admin/roles/new',
        component: RoleEditor,
        meta: {
          displayName: 'Create Role',
          breadcrumbs: ['admin', 'roles',],
        },
      },
      {
        name: 'edit_role',
        path: 'admin/roles/:role_id',
        component: RoleEditor,
        meta: {
          displayName: 'Edit Role',
          breadcrumbs: ['admin', 'roles',],
        },
      },

      {
        name: 'company_settings',
        path: 'admin/company_settings',
        component: CompanySettings,
        meta: {
          displayName: 'Company Settings',
          breadcrumbs: ['admin',],
        },
      },

      {
        name: 'location_settings',
        path: 'admin/location_settings/:location_id',
        component: LocationSettings,
        meta: {
          param: 'location_id',
          displayName: 'Location Settings',
          breadcrumbs: ['admin', 'company_settings',],
        },
      },

      /*======= Internal =======*/
      {
        name: 'components',
        path: 'components',
        component: ComponentLibrary,
      },
      {
        name: 'module_library',
        path: 'modules',
        component: ModuleLibrary,
      },
      {
        name: 'module_editor',
        path: 'modules/:module_id',
        component: ModuleEditor,
      },
      {
        name: 'grid_builder',
        path: 'components/grid',
        component: GridBuilder,
      },
      {
        name: 'render_form',
        path: 'components/renderform',
        component: RenderForm,
      },
      {
        name: 'render_module',
        path: 'components/rendermodule',
        component: RenderModule,
      },
      {
        name: 'datepickers',
        path: 'components/datepickers',
        component: DatePickers,
      },
      {
        name: 'view_dag',
        path: 'view_dag',
        component: ViewDag,
      },
    ],
  },
  /*======= Auth =======*/
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: 'password_reset',
        name: 'password_reset',
        component: PasswordReset,
      },
      {
        path: 'company/:company_id/password_reset',
        name: 'branded_password_reset',
        redirect: 'password_reset',
      },
      {
        name: 'login',
        path: 'login',
        component: LoginPage,
      },
      {
        name: 'branded_login',
        path: '/company/:company_id/login',
        redirect: 'login',
      },
      {
        path: '/company/:company_id/new_user',
        name: 'new_user',
        component: CreateUser,
      },
    ],
  },

  {
    name: 'integrations_redirect_page',
    path: '/integrations/oauth_redirect',
    component: IntegrationsRedirect,
  },

  {
    path: '/404',
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404',
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
