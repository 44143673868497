<template>
  <div class="tab-container">
    <template v-if="!isObjects">
      <div
        v-for="(tab, i) in tabs"
        :key="i"
        :class="{ active: currentTab === tab }"
        class="tab"
        @click="selectTab(tab)">
        {{ tab.split("-").join(" ") }}
      </div>
    </template>
    <template v-else>
      <div
        v-for="(tab, i) in tabs"
        :key="i"
        :class="{ active: currentTab === tab.id, 'error-border': tab.hasErrors }"
        class="tab"
        @click="selectTab(tab.id)">
        {{ tab.label }}
      </div>
    </template>
  </div>
</template>di

<script>
  export default {
    name: 'TabsComponent',
    components: {},
    props: {
      tabs: {
        type: Array,
        default: () => [],
      },

      value: {
        type: String,
        default: '',
      },
      isObjects: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {}
    },

    computed: {
      currentTab: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    mounted () {},

    beforeDestroy () {},

    methods: {
      selectTab (tab) {
        this.currentTab = tab
      },
    },
  }
</script>

<style lang="scss" scoped>
.tab-container {
  display: flex;
  flex-wrap: wrap;
}
.tab {
  padding: 6px 15px;
  cursor: pointer;
  border-radius: 200px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #f5f5f5;
  transition: all 0.3s;
  font-size: 15px;
  text-transform: capitalize;

  &.active {
    background-color: #000;
    color: white;
  }

  &.error {
    border: 2px double red;
  }

  &:hover:not(.active) {
    background-color: rgba(0, 0, 0, 0.03);

    // border: $border-1;
  }
}
</style>
