<template>
  <div class="editor-container">
    <div class="editor-header">
      <div class="widget-type">
        <router-link
          :to="{ name: 'pdf_report_editor', params: { report_id: reportId } }">
          <b-button
            type="is-light"
            class="back-btn mr-5"
            icon-right="arrow-left" />
        </router-link>
        <div class="report-breadcrumbs">
          <div>
            <h4>Report</h4>
            <h2>{{ reportData?.name || "Pdf Report" }}</h2>
          </div>

          <div class="arrow-right">
            <i class="material-icons">chevron_right </i>
          </div>

          <div>
            <h4>Module</h4>
            <h2>{{ module?.title || moduleConfigs.name }}</h2>
          </div>
        </div>
      </div>

      <div class="controls">
        <a-button
          type="button ml-2"
          :disabled="!isDirty"
          hotkeys="ctrl+shift+d"
          class="button is-light"
          @click="getData">
          Discard Changes
        </a-button>
        <a-button
          type="button ml-2"
          :disabled="!isDirty"
          hotkeys="ctrl+shift+s"
          class="button is-primary"
          @click="save">
          Publish Changes & Close
        </a-button>
      </div>
    </div>
    <div class="editor-body">
      <div class="block layout">
        <div class="block-header">
          <h4 class="">
            Modules
          </h4>
        </div>

        <div
          v-if="module"
          class="block-body">
          <b-tooltip
            style="width: 100%"
            position="is-right"
            type="is-light"
            append-to-body="true"
            multilined>
            <div
              class="row mini-view-item"
              :class="{
                selected: !selectedRowKey,
                'error-border': module.errors,
              }"
              @click="openTrackerRowModule(null)">
              <img
                :src="moduleConfigs.icon"
                alt="" />

              <div>
                {{ module?.title || moduleConfigs.name }}
              </div>
            </div>
            <template #content>
              <div class="data-tooltip">
                <div>
                  <h2>Time Dimension:</h2>
                  {{
                    module?.time_dimension?.name ||
                      module?.time_dimension?.key ||
                      "Null"
                  }}
                </div>
                <div v-if="module?.tracker_pivot">
                  <h2>Pivot:</h2>
                  {{
                    module?.tracker_pivot?.dimension?.name ||
                      module?.tracker_pivot?.dimension?.key ||
                      "Null"
                  }}
                </div>
              </div>
            </template>
          </b-tooltip>

          <MiniTrackerTable
            v-if="!isLoading && module"
            :key="module.key"
            v-model="module"
            :current-selected-key="selectedRowKey"
            @open="openTrackerRowModule" />
        </div>
      </div>
      <div class="editor-and-preview">
        <splitpanes class="default-theme">
          <pane
            min-size="20"
            class="pane">
            <div class="block editor">
              <TrackerRow
                v-if="selectedRowKey"
                v-model="selectedRowModule"
                :context="{
                  timeDimension: module?.time_dimension,
                  pivotDimension: module?.tracker_pivot?.dimension,
                }" />
              <TrackerTableEditor
                v-else
                :key="module?.key"
                v-model="module" />
            </div>
          </pane>
          <pane min-size="20">
            <PreviewAndJSONEditor
              v-model="module"
              type="module" />
          </pane>
        </splitpanes>
      </div>
    </div>
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="false" />
  </div>
</template>

<script>
  import MiniTrackerTable from '@/components/pdf_report/MiniTrackerTableModule.vue'
  import TrackerTableEditor from '@/components/pdf_report/tracker_table_module/TrackerTableEditor.vue'
  import TrackerRow from '@/components/pdf_report/tracker_table_module/TrackerRow.vue'
  import uniqid from 'uniqid'
  import { Splitpanes, Pane } from 'splitpanes'
  import PreviewAndJSONEditor from '@/components/pdf_report/PreviewAndJsonEditor.vue'

  export default {
    name: 'TrackerTableEditorPage',
    components: {
      MiniTrackerTable,
      TrackerTableEditor,
      TrackerRow,
      Splitpanes,
      Pane,
      PreviewAndJSONEditor,
    },
    beforeRouteLeave (from, to, next) {
      if (this.isDirty) {
        this.$buefy.dialog.confirm({
          title: 'Unsaved Changes',
          message: 'Are you sure you want to discard changes and leave page?',
          onConfirm: () => next(),
        })
      } else {
        next()
      }
    },
    props: {},
    data () {
      return {
        defaultTimeRange: {
          include_today: false,
          key: '',
          name: '',
          type: '',
        },
        moduleId: null,
        reportId: null,
        module: null,
        reportData: null,

        moduleConfigs: {
          name: 'Tracker Table',
          type: 'TrackerTableModule',
          icon: require('@/assets/icons/tracker-table.svg'),
        },

        selectedRowKey: null,
        selectedRowModule: null,

        isLoading: false,
        isDirty: false,
      }
    },

    computed: {},
    watch: {
      module: {
        handler () {
          this.isDirty = true
        },
        deep: true,
      },
    },

    async mounted () {
      this.moduleId = this.$route.params.module_id
      this.reportId = this.$route.params.report_id

      await this.getData()

      if (!this.module.key) {
        this.module.key = uniqid()
      }

      this.$store.state.temp.companyMeasures =
        await this.$apis.companyConfigs.getCompanyMeasures()

      this.$store.state.temp.companyDimensions =
        await this.$apis.companyConfigs.getCompanyDimensions()

      this.$store.state.temp.companyExpressions =
        await this.$apis.companyConfigs.getCompanyExpressions()

      await this.$store.dispatch('app/reportsConfig/refreshOptions')
      this.selectedRowKey = null
      this.isLoading = false
    },
    methods: {
      async getData () {
        this.isLoading = true
        const response = await this.$apis.pdfReports.getPdfModuleData(
          this.moduleId
        )
        this.module = response

        this.reportData = await this.$apis.pdfReports.getPdfReportData(
          this.reportId
        )

        await this.$nextTick()
        this.isDirty = false
        this.isLoading = false
      },

      removeReport (key) {
        this.$emit('remove', key)
      },

      onError (error) {
        console.log('error', error)
      },

      showModulePicker () {},

      makeInputsDirty () {},

      async save () {
        const response = await this.$apis.pdfReports.updatePdfReportModule(
          this.module
        )

        if (!response) {
          this.$buefy.toast.open({
            message: 'Backend Error, Report this to the devs',
            type: 'is-danger',
          })
          return
        }

        if (response?.errors) {
          this.$buefy.toast.open({
            message: 'Error saving module, Fix the errors and try again',
            type: 'is-warning',
          })

          this.module = {}
          await this.$nextTick()
          await this.$nextTick()
          await this.$nextTick()
          this.module = response
        } else {
          this.module = {}
          await this.$nextTick()
          await this.$nextTick()
          await this.$nextTick()
          this.module = response
          this.$buefy.toast.open({
            message: 'Changes Saved',
            type: 'is-success',
            position: 'is-bottom',
          })

          await this.$nextTick()
          await this.$nextTick()
          this.isDirty = false

          this.$router.push({
            name: 'pdf_report_editor',
            params: { report_id: this.reportId, },
          })
        }
      },

      async deleteModule () {
        this.isLoading = true

        if (await this.$apis.pdfReports.deletePdfReportModule(this.moduleId)) {
          this.$router.push({
            name: 'pdf_report_editor',
            params: { report_id: this.reportId, },
          })
        } else {
          this.$buefy.toast.open({
            message: 'Error deleting module',
            type: 'is-danger',
            position: 'is-bottom',
          })
        }
        this.isLoading = true
      },

      openTrackerRowModule (index = null) {
        if (index === null) {
          this.selectedRowModule = null
          this.selectedRowKey = null
          return
        }

        this.selectedRowModule = this.module.tracker_rows[index]
        this.selectedRowKey = this.selectedRowModule.key
      },
    },
  }
</script>

<style lang="scss" scoped>
.data-tooltip {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;

  hr {
    margin: 5px 0;
    border: 0;
    border-top: 1px solid black;
  }
  h2 {
    font-size: 12px;
    font-weight: 600;
  }
}
.report-breadcrumbs {
  display: flex;

  h2 {
    font-weight: 400;
    font-size: 20px;
    margin: 0;
  }

  h4 {
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    color: $grayscale-3;
  }

  .arrow-right {
    display: flex;
    align-items: center;
    margin: 0 10px;
    font-size: 20px;
    color: #b0b0b0;
  }
}
.back-btn {
  font-size: 20px !important;
  border-radius: 100% !important;
}
.editor-container {
  padding: 0 20px 5px;
  width: 100%;
  height: 100%;
  margin: auto !important;
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden !important;
}

.editor-body {
  display: flex;
  max-height: 100%;
  margin: 0 !important;
  overflow: hidden !important;
}

.block {
  border: $border-1;
  border-radius: 3px;
  height: 100%;
  flex-direction: column;
  display: flex;

  .block-header {
    min-height: 40px;
    align-items: center;
    padding: 0 5px;
    border-bottom: $border-1;
    background-color: white;
    display: flex;

    h4 {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      padding: 0 10px;
      text-transform: capitalize;
      margin: 0;
      color: black;
    }
  }
  .block-body {
    padding: 10px 10px;
    background-color: #fbfbfb;
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  &.layout {
    max-height: 100%;
    overflow: hidden !important;
    margin-right: 10px;
    width: 300px;
    margin-bottom: 0 !important;

    .block-body {
      overflow-y: auto;
    }
  }

  &.editor {
    margin: 0 !important;
  }

  &.preview {
    height: 100%;
    .block-header {
      width: 100%;
    }

    .block-body {
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
    }

    .output {
      background-color: white;
      height: 100%;
      overflow: auto;
    }
  }
}

.editor-and-preview {
  width: 100%;
}

.editor-header {
  margin-bottom: 20px;

  display: flex;
  padding-top: 10px;
  .widget-type {
    display: flex;
    font-weight: 400;
    font-size: 15px;
    align-items: center;
    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      border: $border-1;
      border-radius: 8px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .controls {
    margin-left: auto;
    display: flex;
    align-items: center;

    .button {
      padding: 0 15px;
      font-size: 12px;
    }
  }
}

.mini-view-item {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s;
  width: 100%;
  padding: 10px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  font-size: 15px;
  background-color: white;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
    0px 1px 2px 0px rgba(40, 39, 43, 0.06);
  &.add-button {
    background-color: rgba(0, 0, 0, 0.03);
    transition: background-color 0.1s;
    border: $border-1;
    display: flex;
    align-items: center;
  }
  img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    transition: background-color 0.1s;
  }
  &.selected {
    background-color: $primary;
    color: white;
    img {
      filter: brightness(0) invert(1);
    }
  }
}
</style>
