<template>
  <div class="row-editor-container">
    <div class="editor-header">
      <div class="widget-type">
        <img
          :src="moduleConfigs.icon"
          alt="" />
        <div>{{ moduleConfigs.name }}</div>
      </div>
      <Tabs
        v-model="inputSectionTab"
        :tabs="tabs"
        :is-objects="true"
        @input="scrollToSection" />
    </div>

    <div
      class="editor"
      @scroll="throttleScroll">
      <div
        id="display-label"
        class="editor-section">
        <h2>Display label</h2>
        <div class="body">
          <b-field
            label="Display Label"
            class="input-field"
            message="">
            <b-input
              v-model="inputs.name"
              type="" />
          </b-field>
        </div>
      </div>
      <div
        id="row-style"
        class="editor-section">
        <h2>Row Style</h2>
        <div class="body">
          <div class="m-0">
            <b-field
              label="Row Type"
              class="input-field"
              message="">
              <b-select
                v-model="inputs.style.row_type"
                placeholder="Select a row type"
                expanded
                style="width: 100%">
                <option
                  v-for="option in rowStyleTypesOptions"
                  :key="option"
                  :value="option">
                  {{ option }}
                </option>
              </b-select>
            </b-field>

            <b-field class="input-field">
              <b-switch
                v-model="inputs.style.add_divider"
                class="mt-3"
                type="is-info">
                Add Divider
              </b-switch>
            </b-field>
          </div>
        </div>
      </div>
      <div
        id="measure"
        class="editor-section">
        <h2>Measure</h2>
        <div class="body">
          <MeasureConfigEditor
            v-model="inputs.measure_config"
            :context="{
              reportDimensions: [].concat(
                context.timeDimension ? [context.timeDimension] : [],
                context.pivotDimension ? [context.pivotDimension] : []
              ),
            }" />
        </div>
      </div>

      <div
        id="format"
        class="editor-section">
        <h2>Format</h2>
        <div class="body">
          <DataFormatEditor v-model="inputs.data_format" />
        </div>
      </div>

      <div
        id="benchmark"
        class="editor-section">
        <h2>benchmark</h2>
        <div class="body">
          <BenchmarkConfigEditor
            v-model="inputs.comparison_config"
            :context="{
              parentMeasure: inputs.measure_config?.measure,
              reportDimensions: [].concat(
                context.timeDimension ? [context.timeDimension] : [],
                context.pivotDimension ? [context.pivotDimension] : []
              ),
            }" />
        </div>
      </div>

      <div
        id="total-columns"
        class="editor-section">
        <h2>Total Column</h2>
        <div class="body">
          <TrackerRowTotals
            v-model="inputs.tracker_row_totals"
            :context="context"
            class="sub-section" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TrackerRowTotals from '@/components/pdf_report/tracker_table_module/TrackerRowTotals.vue'
  import MeasureConfigEditor from '@/components/reports/config/modules/common/MeasureConfigEditor'
  import BenchmarkConfigEditor from '@/components/reports/config/modules/common/BenchmarkConfigEditor'
  import Tabs from '@/components/pdf_report/Tabs.vue'
  import { throttle } from 'lodash'

  import DataFormatEditor from '@/components/reports/config/modules/common/DataFormatEditor'

  export default {
    name: 'TrackerRow',
    components: {
      TrackerRowTotals,
      MeasureConfigEditor,
      BenchmarkConfigEditor,
      Tabs,
      DataFormatEditor,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
      context: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        nameInput: '',
        measureFilterToggle: false,
        rowStyleTypesOptions: ['Primary', 'Secondary',],

        moduleConfigs: {
          name: 'Tracker Row',
          type: 'TrackerTableRow',
          hideInLibrary: true,
          icon: require('@/assets/icons/tracker-row.svg'),
        },

        inputSectionTab: 'display-label',
      }
    },

    computed: {
      inputs: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      throttleScroll () {
        return throttle(this.onScroll, 100)
      },

      tabs () {
        return [
          {
            label: 'Display label',
            id: 'display-label',
            hasErrors: false,
          },
          {
            label: 'Row Style',
            id: 'row-style',
            hasErrors: this.inputs?.style?.errors,
          },
          {
            label: 'Measure',
            id: 'measure',
            hasErrors: this.inputs?.measure_config?.errors,
          },
          {
            label: 'Format',
            id: 'format',
            hasErrors: this.inputs?.data_format?.errors,
          },
          {
            label: 'Benchmark',
            id: 'benchmark',
            hasErrors: this.inputs?.comparison_config?.errors,
          },
          {
            label: 'Total Column',
            id: 'total-columns',
            hasErrors: this.inputs?.tracker_row_totals?.some((f) => f.errors),
          },
        ]
      },
    },

    async mounted () {},

    beforeDestroy () {},

    methods: {
      onScroll (event) {
        const sectionsByDistanceFromTop = this.tabs.map((section) => {
          const el = document.getElementById(section.id)
          if (!el) return
          const rect = el.getBoundingClientRect()
          const scrollableDivRect = event.target.getBoundingClientRect()
          return {
            section: section.id,
            top: Math.abs(rect.top - scrollableDivRect.top),
          }
        })

        const sortedSections = sectionsByDistanceFromTop.sort(
          (a, b) => a.top - b.top
        )
        this.inputSectionTab = sortedSections[0].section
      },
      scrollToSection (sectionId) {
        const element = document.getElementById(sectionId)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.editor-header {
  border-bottom: $border-1;
  padding: 20px;
  background: white;

  .widget-type {
    display: flex;
    font-weight: 400;
    font-size: 18px;
    align-items: center;
    margin-bottom: 15px;
    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      border: $border-1;
      border-radius: 8px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.row-editor-container {
  width: 100%;
  height: 100%;
  margin: auto !important;
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.editor {
  padding: 20px;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-direction: column;

  .editor-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    h2 {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
      text-transform: capitalize;
    }

    .body {
      border: $border-1;
      border-radius: 10px;
      padding: 18px;
      background: white;
    }
  }
}
</style>
